import React, { useState, useEffect } from "react";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	HomeOutlined,
	LogoutOutlined,
	TeamOutlined,
	BankOutlined,
	FileTextOutlined,
	BarChartOutlined,
	DatabaseOutlined,
	FileExcelOutlined,
	SettingOutlined,
	ShopOutlined,
	ShoppingCartOutlined,
	CloudUploadOutlined,
	ClusterOutlined
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Result, Cascader, message } from "antd";
import PurchaseInvoices from "../../components/invoices/purchase_invoices";
import SaleInvoices from "../../components/invoices/sale_invoices";
import UploadInvoices from "../../components/invoices/uploader_invoices";
import Start from "../../components/home/home";
import Reports from "../../components/reports/reports";
import { get_companies } from "../../services/companies";
import Classification_Recurrencies from "../../components/invoices/Classification_Recurrencies";
import PropTypes from "prop-types";

const { Header, Sider, Content, Footer } = Layout;

const no_selected_company = {
	id: "",
	name: "",
	country: "",
	address: "",
	is_associated_company: true,
	description: "",
	database_id: "",
	tid: "",
	accounting_resources: {
		diaries: [],
		accounting_plan: [],
		expense_accounts: [],
		sale_accounts: [],
		others:{
			analytic_accounts:[]
		}
	},
	tax_authority_specifications: {
		pequenio_contribuyente: false,
	},
};

const Home = ({ user, token, setUser, setToken }) => {
	const handleSignOut = () => {
		setUser({});
		setToken({});
	};

	const [current, setCurrent] = useState("Home");
	const [collapsed, setCollapsed] = useState(false);
	const [menuItems, setMenuItems] = useState([
		{ key: "Home", icon: <HomeOutlined />, label: "Inicio" },
		{ key: "Sign Out", icon: <LogoutOutlined />, label: "Salir" },
	]);

	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(no_selected_company);
	const [loadingCompanies, setLoadingCompanies] = useState(true);

	const [selectorOfCompanies, setSelectorOfCompanies] = useState([]);
	const displayRender = (labels) => labels[labels.length - 1];

	const { token: themeToken } = theme.useToken();
	const { colorBgContainer } = themeToken;

	const refreshCompaniesFromDb = async () => {
		try {
			const res = await get_companies(token, "assigned_companies_from_databases");
			if(res){
				const selector = res.filter((database) => database.children.length > 0);
				setSelectorOfCompanies(selector);
				setLoadingCompanies(false);
			}
		} catch (error) {
			console.log(error.response);
			message.error(error.response);
		}
	};
	
	const refreshCompanies = async () => {		
		try {
			const res = await get_companies(token, "assigned");
			if (res) {
				setCompanies(res);
			}
		} catch (error) {
			console.log(error);
			//message.error(error.response);
		}

		refreshCompaniesFromDb();
	};

	const updateMenuItemsForRole = (role, items) => {
		const updatedList = [...menuItems];
		updatedList.splice(updatedList.length - 1, 0, ...items);
		setMenuItems(updatedList);
	};

	const getItem = (label, key, icon, children, type) => {
		return {
			key,
			icon,
			children,
			label,
			type,
		};
	};

	const accountant_menu = [
		getItem("Facturas", "Invoices", <FileTextOutlined />, [
			getItem("Compras", "PurchaseInvoices", <ShoppingCartOutlined />),
			getItem("Ventas", "SaleInvoices", <ShopOutlined />),
			getItem("Subir", "UploadInvoices", <CloudUploadOutlined />),
		]),
		getItem("Reportes", "Reports", <BarChartOutlined />, [
			getItem("Facturas", "InvoiceReport", <FileTextOutlined />),
		]),
	];

	const admin_menu = [
		...accountant_menu,
	];

	useEffect(() => {
		refreshCompanies();

		if (Object.keys(user).length !== 0) {
			if (user.role === "accountant") {
				updateMenuItemsForRole("accountant", accountant_menu);
			}

			if (user.role === "admin") {
				updateMenuItemsForRole("admin", admin_menu);
			}
		}
	}, []);

	useEffect(() => {
		if (selectedCompany !== no_selected_company) {
			onChangeCompany(selectedCompany.id);
		}
	}, [companies]);

	const onChangeCompany = (value) => {
		if (value == undefined){
			setSelectedCompany(no_selected_company);
		}
		else {
			const found_company = companies.find((element) => element.id === value[1]);
			if (found_company == undefined){
				setSelectedCompany(no_selected_company);
			}
			else {
				setSelectedCompany(found_company);
			}
		}
	};

	const selectOption = (e) => {
		setCurrent(e.key);
		if (e.key === "Sign Out") handleSignOut();
	};

	const renderContent = () => {
		switch (current) {
		case "PurchaseInvoices":
			return (
				<PurchaseInvoices
					{...{user, token, company: selectedCompany }}
				/>
			);
		case "SaleInvoices":
			return (
				<SaleInvoices
					{...{ user, token, company: selectedCompany }}
				/>
			);
		case "UploadInvoices":
			return (
				<UploadInvoices {...{token, companies_of_user : selectorOfCompanies}}/>
			);
		case "InvoiceReport":
			return (
				<Reports
					{...{ user, token, setUser, setToken, company: selectedCompany }}
				/>
			);
		case "Home":
			return (
				<Start
					{...{ company: selectedCompany }}
				/>
			);
		default:
			return null;
		}
	};

	return (
		<Layout style={{ height: "100vh" }}>
			{user ? (
				<>
					<Sider trigger={null} collapsible collapsed={collapsed}>
						<div style={{ height: "64px" }} className="demo-logo-vertical" />
						<Menu
							theme="dark"
							mode="inline"
							onClick={selectOption}
							selectedKeys={[current]}
							items={menuItems}
						/>
					</Sider>
					<Layout>
						<Header
							style={{
								padding: 0,
								background: colorBgContainer,
								textAlign: "start",
							}}
						>
							<Button
								type="text"
								icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
								onClick={() => setCollapsed(!collapsed)}
								style={{ fontSize: "16px", width: 64, height: 64 }}
							/>
							{
								(
									current === "Home" ||
									current === "PurchaseInvoices" ||
									current === "SaleInvoices" ||
									current === "InvoiceReport"
								) && (
									<Cascader
										style={
											{
												width: 500
											}
										}
										placeholder="Selecciona una empresa"
										options={selectorOfCompanies}
										expandTrigger="hover"
										displayRender={displayRender}
										onChange={onChangeCompany}
										loading={loadingCompanies}
									/>
								)}
							{
								(
									current == "PurchaseInvoices" &&
                  <Classification_Recurrencies company = {selectedCompany} token = {token} position={"out"}
                  ></Classification_Recurrencies>
								)
							}
							{
								(
									current == "SaleInvoices" &&
                  <Classification_Recurrencies company = {selectedCompany} token = {token} position={"in"}
                  ></Classification_Recurrencies>
								)
							}
						</Header>
						<Content
							style={{
								margin: "10px 16px",
								padding: 24,
								minHeight: 280,
								background: colorBgContainer,
							}}
						>
							{renderContent()}
						</Content>
						<Footer
							style={{
								textAlign: "center",
								height: "20px",
								marginBottom: "10px",
								marginTop: "-10px",
							}}
						>
							ARO ©2024 Powered By Olive Tech
						</Footer>
					</Layout>
				</>
			) : (
				<Result
					status="403"
					title="403"
					subTitle="Lo sentimos, no estás autorizado a hacer esto."
					extra={<Button type="primary">Back Home</Button>}
				/>
			)}
		</Layout>
	);
};

Home.propTypes = {
	user: PropTypes.shape({
		role: PropTypes.string.isRequired
	}).isRequired,
	token: PropTypes.string.isRequired,
	setUser: PropTypes.func.isRequired,
	setToken: PropTypes.func.isRequired,
};

export default Home;
