import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_companies = async (token, type_search, id="") => {
	let body = {
		token,
		type_search
	};
	if (type_search == "specific" && id != "") {
		body.id = id;
	}

	try {
		const res = await axios.post(`${apiUrl}/get_companies`, body);
		return res.data;
	} catch (err) {
		throw err;
	}
};