import React, { useEffect } from "react";
import { Spin, message, Result, Form } from "antd";
import axios from "axios";
import PropTypes from "prop-types";

const apiUrl = process.env.REACT_APP_API_URL;
const client_id = process.env.REACT_APP_CLIENT_ID;

// Before: const Login = ({user, token, setUser, setToken})
const Login = ({ setUser, setToken }) => {
	const handleCallbackResponse = (response) => {
		setToken(response.credential);
		document.getElementById("signInDiv").hidden = true;
		document.getElementById("spin").hidden = false;
		document.getElementById("aroForm").hidden = true;
		document.getElementById("aroTitle").hidden = true;

		axios
			.post(`${apiUrl}/auth_user`, {
				token: response.credential,
			})
			.then((res) => {
				console.log(res.data);
				setUser(res.data);
			})
			.catch((err) => {
				console.error(err);
				//message.error(err.response.data);
				document.getElementById("spin").hidden = true;
				document.getElementById("aroForm").hidden = false;
				document.getElementById("aroTitle").hidden = false;
				document.getElementById("accessDenied").hidden = false;
			});
	};

	useEffect(() => {
		const loadGoogleApi = async () => {
			try {
				const script = document.createElement("script");
				script.src = "https://accounts.google.com/gsi/client";
				script.async = true;
				script.onload = () => {
					/* global google */
					google.accounts.id.initialize({
						client_id,
						callback: handleCallbackResponse,
					});

					google.accounts.id.renderButton(
						document.getElementById("signInDiv"),
						{
							theme: "outline",
							size: "large",
						}
					);

					google.accounts.id.prompt();
				};

				document.head.appendChild(script);
			} catch (error) {
				console.error("Error loading Google API script:", error);
			}
		};

		loadGoogleApi();
	}, []);

	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div id="spin" hidden>
				<Spin size="large" />
			</div>
			<Form
				autoComplete="off"
				style={{ 
					backgroundColor: "#1677ff", 
					padding: "20px",
					borderRadius: "8px",
				}}
				id="aroForm"
			>
				<h2
					id="aroTitle"
					style={{ color: "white", fontSize: "40px"}}
				>ARO
				</h2>
				<div id="signInDiv"></div>
				<div id="accessDenied" hidden>
					<Result
						status="403"
						title="403"
						subTitle="Lo sentimos, no puedes acceder a esta página."
					/>
				</div>
			</Form>
		</div>
	);
};

Login.propTypes = {
	setUser: PropTypes.func.isRequired,
	setToken: PropTypes.func.isRequired,
};

export default Login;
