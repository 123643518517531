import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;
import pako from "pako";
import PropTypes from "prop-types";
import "./uploader.css";
import { endpoint_uploader_invoices } from "../../services/invoices";

const compressFile = async (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (e) {
			try {
				const compressed = pako.gzip(new Uint8Array(e.target.result));
				const blob = new Blob([compressed], { type: "application/gzip" });
				resolve({
					compressedFile: new File([blob], file.name + ".gz", { type: "application/gzip" }),
					originalContentType: file.type
				});
			} catch (err) {
				reject(err);
			}
		};
		reader.onerror = (err) => reject(err);
		reader.readAsArrayBuffer(file);
	});
};
  

const UploadInvoices = (props) => {
	const handleUpload = async (options) => {
		const { onSuccess, onError, file } = options;
		try {
			const { compressedFile, originalContentType } = await compressFile(file);
			const formData = new FormData();
			formData.append("file", compressedFile);
			formData.append("originalContentType", originalContentType);
			formData.append("token", props.token);

			fetch(endpoint_uploader_invoices, {
				method: "POST",
				body: formData,
			})
			.then(response => {
				if (response.ok) {
					onSuccess("Ok");
				} else {
					return response.text().then(errorText => {
						throw new Error(errorText);
					});
				}
			})
			.catch(error => {
				onError(error.message);
			});
		} catch (error) {
			onError(error);
		}
	};

	return (
		<>
			<h1>Uploader de Facturas</h1>
			{props.companies_of_user.length === 0 && <h2>No tienes compañias asignadas, ingresa tus compañias</h2>}
			{props.companies_of_user.length > 0 && (
				<div style={{ height: "60%" }}>
					<Dragger
						name="file"
						multiple={true}
						customRequest={handleUpload}
						onChange={(info) => {
							if (info.file.status === "done") {
								//message.success(`${info.file.name} enviado correctamente.`);
							} else if (info.file.status === "error") {
								message.error(`${info.file.name}  -  ${info.file.error}`);
							}
						}}
					>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Tu Uploader está listo. Agrega tus facturas</p>
						<p className="ant-upload-hint">
              Puedes subir archivos xml o archivos excel.
						</p>
					</Dragger>
				</div>
			)}
		</>
	);
};

UploadInvoices.propTypes = {
	token: PropTypes.string.isRequired,
	companies_of_user: PropTypes.array.isRequired,
};

export default UploadInvoices;
