import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_invoices = async (token, company_tid, state, type_of_invoice, start_date = "", end_date ="", last_element = "") => {
	var body = {
		token,
		state,
		company_tid,
		type_of_invoice,
	};

	if (start_date != "" && end_date != "") {
		body.start_date = start_date;
		body.end_date = end_date;
	}

	if (last_element != "") {
		body.last_element = last_element;
	}
    
	try {
		const res = await axios.post(`${apiUrl}/get_invoices`, body);
		console.log(res)
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const edit_invoice = async (token, edit_value, data, type_of_invoice) => {
	const body = {
		token,
		type_of_invoice,
		edit_value,
		data
	};
  
	try {
		const response = await axios.post(`${apiUrl}/edit_invoice`, body);
		return response;
	} catch (err) {
		throw err;
	}
};

export const get_nomenclature = async (token, nit) => {
	try {
		const res = await axios.post(`${apiUrl}/get_nomenclatures`, {
			token,
			nit
		});
		return res.data;
	} catch (err) {
		throw err;
	}
};

export const endpoint_uploader_invoices = `${apiUrl}/cf_uploader`;

export const frases = {
	"1": {
		"1": "Sujeto a pagos trimestrales ISR",
		"2": "Sujeto a retención definitiva ISR",
		"3": "Sujeto a pago directo ISR"
	},
	"2": {
		"1": "Agente de Retención del IVA"
	},
	"3": {
		"1": "No genera derecho a crédito fiscal"
	},
	"4": {
		"1": "Exenta del IVA (art. 7 num. 2 Ley del IVA)",
		"2": "Exenta del IVA (art. 7 num. 4 Ley del IVA)",
		"3": "Exenta del IVA (art. 7 num. 5 Ley del IVA)",
		"4": "Exenta del IVA (art. 7 num. 9 Ley del IVA)",
		"5": "Exenta del IVA (art. 7 num. 10 Ley del IVA)",
		"6": "Exenta del IVA (art. 7 num. 13 Ley del IVA)",
		"7": "Exenta del IVA (art. 7 num. 14 Ley del IVA)",
		"8": "Exenta del IVA (art. 8 num. 1 Ley del IVA)",
		"9": "Exenta del IVA (art. 7 num. 15 Ley del IVA)",
		"10": "Esta factura no incluye IVA (art. 55 Ley del IVA)",
		"11": "No afecta al IVA (Decreto 29-89 Ley de Maquila)",
		"12": "No afecta al IVA (Decreto 65-89 Ley de Zonas Francas)",
		"13": "Exenta del IVA (art. 7 num. 12, Ley del IVA)",
		"14": "Exenta del IVA (art. 7 num. 6 Ley del IVA)",
		"15": "Exenta del IVA (art. 7 num. 11 Ley del IVA)",
		"16": "Exenta del IVA (art. 8 num. 2 Ley del IVA)",
		"17": "Exenta del IVA (art. 32 literal c, Ley Orgánica Zolic)",
		"18": "(Contribuyentes con disposiciones específicas de exención al IVA)",
		"19": "Exenta del IVA (art. 3 num. 7 Ley del IVA)",
		"20": "Aportes (art. 35 Ley de Fortalecimiento al Emprendimiento)",
		"21": "Cargos e impuestos no sujetos a IVA (Aerolíneas)",
		"22": "Factura origen no incluye IVA",
		"23": "Exenta del IVA (art. 7, numeral 3, literal c, Ley del IVA)",
		"24": "No afecto al IVA (Fuera del hecho generador art. 3, 7 y 8, Ley del IVA)",
		"25": "Exenta del IVA (art. 31 Dec. 22-73 Ley Orgánica Zolic)",
		"26": "Exenta del IVA (art. 4 Dec. 31-2022 Ley de Fom. del Trab. Temp. en el Extranjero)",
		"27": "Exenta del IVA (art. 7 literal “a” Dec. 40-2022 Ley Inc. Mov. Eléctrica)",
		"28": "Exenta del IVA (art. 7 literal “c” Dec. 40-2022 Ley Inc. Mov. Eléctrica)",
		"29": "Exenta del IVA (art. 7 literal “d” Dec. 40-2022 Ley Inc. Mov. Eléctrica)",
		"30": "Exenta del IVA (art. 7 literal “g” Dec. 40-2022 Ley Inc. Mov. Eléctrica)",
		"31": "Exenta del IVA (art. 7 literal “h” o “i” Dec. 40- 2022 Ley Inc. Mov. Eléctrica)"
	},
	"5": {
		"1": "El vendedor o prestador del servicio se negó a emitir la factura correspondiente. (art. 52 Ley del IVA)"
	},
	"6": {
		"1": "Con forma de pago sobre las ventas brutas",
		"2": "Con forma de pago sobre las utilidades, no retener"
	},
	"7": {
		"1": "No retener",
		"2": "No retener"
	},
	"8": {
		"1": "Exenta del ISR (art. 8 núm. 2 Ley de Actualización Tributaria)",
		"2": "Exenta del ISR (art. 8 núm. 3 Ley de Actualización Tributaria)",
		"3": "Exenta del ISR (art. 8 núm. 5 Ley de Actualización Tributaria)",
		"4": "Exenta del ISR (art. 11 núm. 1 Ley de Actualización Tributaria)",
		"5": "Exenta del ISR (art. 11 núm. 2 Ley de Actualización Tributaria)",
		"6": "Exenta del ISR (art. 31 Ley Orgánica Zolic)",
		"7": "Exenta del ISR (art. 32 litar a, Ley Orgánica Zolic)",
		"8": "Exenta del ISR (art. 8 núm. 1 Ley de Actualización Tributaria)",
		"9": "Exenta del ISR (Art. 4, literal b), numeral ii, Dec. 40-2022, Ley Inc. Mov. Eléctrica)",
		"10": "Exenta del ISR (Art. 7, literal f, Dec. 40-2022, Ley Inc. Mov. Eléctrica)",
		"11": "Exenta del ISR (Art. 7, literal g, Dec. 40-2022, Ley Inc. Mov. Eléctrica)"
	},
	"9": {
		"1": "Esta factura incluye apoyo social temporal al precio del gas licuado envasado en cilindros portátiles",
		"2": "Esta factura incluye apoyo social temporal Decreto 42-2022",
		"3": "Monto total a pagar sin apoyo social GTQ - XXX",
		"4": "Exenta Impuesto Salida Vía Aérea (art. 4 Dec. 31-2022 Ley de Fom. del Trab. Temp. en el Extranjero)",
		"5": "Esta factura incluye apoyo social temporal al precio del gas licuado envasado en cilindros portátiles",
		"6": "Esta factura incluye apoyo social temporal Decreto 42-2022"
	}
};
  
